import "./styles.css";
import { useState, useRef } from "react";
import { ApiRegion } from "@amityco/js-sdk";
import {
  AmityUiKitProvider,
  AmityUiKitSocial,
  AmityUiKitChat,
  AmityPageTypes,
} from "@amityco/ui-kit-open-source";
import Login from "./Login";
import GetCommunity from "./GetCommunity";

const apiKey = "b0e8b9593bdca3634565db15030942de8301ddb3e9373c2c";
const bbvrGlobalCommunityId = "64af01593714fddb10e0c7d2";

export default function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  var [userId, setUserId] = useState();

  if (queryParameters.get("userId") != null) {
    userId = queryParameters.get("userId");
  }
  // userId = "639938246558f32c73a448d0"
  // userId = "5cb20e57c7a3f404ff343783"

  var type = "feed";
  var secondUserId = "";

  if (queryParameters.get("type") != null) {
    type = queryParameters.get("type");
  }

  if (queryParameters.get("secondUserId") != null) {
    secondUserId = queryParameters.get("secondUserId");
  }

  var postId = ""; //651f878a8657a77ffd386c7e
  if (type === "post") {
    if (queryParameters.get("postid") != null) {
      postId = queryParameters.get("postid");
    }
  }

  var displayName = "undefined";
  if (queryParameters.get("username") != null) {
    displayName = queryParameters.get("username");
  }

  var authToken = "undefined";
  if (
    queryParameters.get("auth") != null &&
    queryParameters.get("auth") !== ""
  ) {
    authToken = queryParameters.get("auth");
  }

  console.log(
    "AB - type: " +
      type +
      ", postId: " +
      postId +
      ", FULL: " +
      window.location.href
  );

  const ref = useRef();

  const handleConnect = () => ref.current.connect();
  const handleDisconnect = () => ref.current.disconnect();

  const handleConnected = () => {
    console.log("connected");
  };
  const handleDisconnected = () => console.log("disconnected");

  const [bbvrGlobalCommunity, setBbvrGlobalCommunity] = useState();

  if (bbvrGlobalCommunity && !bbvrGlobalCommunity.community.isJoined) {
    console.log("Joining community");
    bbvrGlobalCommunity.joinCommunity();
  }

  const bbvrTheme = {
    palette: {
      alert: "#FA4D30",
      base: "#2c1c52",
      primary: "#3210de",
      secondary: "#ab18a6",
      tertiary: "#ba30ff",
      neutral: "#17181C",
      highlight: "#b810de",

      // system: {
      //   borders: '#121010',
      //   background: '#000000',
      // },
    },

    typography: {
      global: {
        fontFamily:
          "Roboto, -apple-system, BlinkMacSystemFont, Arial, sans-serif",
        fontStyle: "normal",
      },
      headline: {
        fontWeight: 600,
        fontSize: "20px",
      },
      title: {
        fontWeight: 600,
        fontSize: "16px",
      },
      body: {
        fontWeight: "normal",
        fontSize: "14px",
      },
      bodyBold: {
        fontWeight: 600,
        fontSize: "14px",
      },
      caption: {
        fontWeight: "normal",
        fontSize: "12px",
      },
      captionBold: {
        fontWeight: 600,
        fontSize: "12px",
      },
    },
  };

  return (
    <div className="App">
      {!userId && <Login onSubmit={setUserId} />}

      {userId && displayName !== "undefined" && (
        <AmityUiKitProvider
          key={userId}
          apiKey={apiKey}
          userId={userId}
          displayName={displayName}
          authToken={authToken}
          apiRegion={ApiRegion.US}
          theme={bbvrTheme}
          onConnected={handleConnected}
          onDisconnected={handleDisconnected}
        >
          {!bbvrGlobalCommunity && (
            <GetCommunity
              communityId={bbvrGlobalCommunityId}
              response={setBbvrGlobalCommunity}
            />
          )}

          {type === "feed" && (
            <AmityUiKitSocial landingPage={AmityPageTypes.NewsFeed} />
          )}

          {type === "search" && (
            <AmityUiKitSocial landingPage={AmityPageTypes.Search} />
          )}

          {type === "chatsearch" && (
            <AmityUiKitSocial landingPage={AmityPageTypes.ChatSearch} />
          )}

          {type === "post" && (
            <AmityUiKitSocial
              landingPage={AmityPageTypes.Post}
              postId={postId}
            />
          )}

          {type === "teamChat" && <AmityUiKitChat />}
          {type === "chat" && <AmityUiKitChat
            type="member"
            secondUserId={secondUserId}
          />}
        </AmityUiKitProvider>
      )}

      {userId && displayName === "undefined" && (
        <AmityUiKitProvider
          key={userId}
          apiKey={apiKey}
          userId={userId}
          authToken={authToken}
          apiRegion={ApiRegion.US}
          theme={bbvrTheme}
          onConnected={handleConnected}
          onDisconnected={handleDisconnected}
        >
          {!bbvrGlobalCommunity && (
            <GetCommunity
              communityId={bbvrGlobalCommunityId}
              response={setBbvrGlobalCommunity}
            />
          )}

          {type === "feed" && (
            <AmityUiKitSocial landingPage={AmityPageTypes.NewsFeed} />
          )}

          {type === "search" && (
            <AmityUiKitSocial landingPage={AmityPageTypes.Search} />
          )}

          {type === "chatsearch" && (
            <AmityUiKitSocial landingPage={AmityPageTypes.ChatSearch} />
          )}

          {type === "post" && (
            <AmityUiKitSocial
              landingPage={AmityPageTypes.Post}
              postId={postId}
            />
          )}

          {type === "teamChat" && <AmityUiKitChat />}
          {type === "chat" && <AmityUiKitChat
            type="member"
            secondUserId={secondUserId}
          />}
        </AmityUiKitProvider>
      )}
    </div>
  );
}
