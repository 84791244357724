import { getAmityCommunity } from "@amityco/ui-kit-open-source";

export default function GetCommunity({communityId , response = () => {} }) {
    const {community: bbvrGlobalCommunity, joinCommunity: joinCommunity } = getAmityCommunity(communityId);

    if (bbvrGlobalCommunity.displayName)
    {
        const returnCommunity = 
        {
            "community": bbvrGlobalCommunity,
            "joinCommunity": joinCommunity
        };
        response(returnCommunity);
    }
    return null;
  }